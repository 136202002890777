import Header from "../components/Header"
import { useEffect } from "react";

const Home = () =>{
    useEffect(() => {
        document.title = 'DPRSSD';
    }, []);

    return(
        <div className="w-full min-h-screen px-10 bg-landing bg-fixed bg-cover bg-center relative overflow-hidden">
            <Header />
        </div>
    )
}

export default Home
import Header from "../components/Header"
import { useEffect, useState } from "react";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai"

const Faq = () => {

    useEffect(() => {
        document.title = 'Frequently Asked Question';
    }, []);

    return (
        <div className="w-full min-h-screen px-10 pb-20 relative overflow-hidden">
            <Header />
            <div className="w-full flex flex-col items-center">
                <h1 className="mt-20 text-6xl lg:text-7xl text-white w-full max-w-[1216px] font-semibold">Depressing Questions</h1>

                <Accordion classes="mt-10" question="What blockchain will DPRSSD reside on?" answer={<><b>DPRSSD</b> will launch on Ethereum Blockchain</>}/>
                <Accordion classes="mt-7" question="What will be the mint price?" answer={<>0.0079 ETH for <b>WL</b>, 0.0099 for <b>Public Sale</b></>}/>
                <Accordion classes="mt-7" question="What is the total supply?" answer={<>Total supply is going to be <b>5555 NFTs</b></>}/>
                <Accordion classes="mt-7" question="What is a roadmap for DPRSSD?" answer={<><b>DPRRSD</b> is an art-based project and a movement for self-improvement. We do have a vision of creating a large ecosystem, where the original <b>DPRSSD</b> collection is going to take a central part. One of the very first steps is going to be a launch of our pixelated Ordinals collection. We do recognize that adaptability is one of the main qualities of successful projects, it allows your project to respond to changing market conditions, emerging technologies, and evolving user preferences. It enables you to stay relevant and meet the needs of your community as they evolve over time.</>}/>
            </div>
        </div>
    )
}

const Accordion = ({
    question = "",
    answer = <></>,
    classes = ""
}) =>{
    const [opened, setOpened] = useState(false)

    useEffect(() =>{
        console.log(opened)
    }, [opened])

    return(
        <div className={classes + " w-full max-w-[1216px] px-5 py-6 text-lg text-left cursor-pointer lg:py-8 lg:px-8 lg:text-2xl bg-white text-black rounded-2xl group"} onClick={() => { setOpened(!opened) }}>
            <div className="flex justify-between">
                {question}

                <div className={!opened && "transition-transform duration-700 group-hover:rotate-[180deg]"}>
                    {
                        opened ? 
                            <AiOutlineMinus color="#000" size={30} className="mt-0"/>
                            :
                            <AiOutlinePlus color="#000" size={30} className="mt-0"/>
                    }
                </div>
            </div>

            <div className={"overflow-y-hidden w-full transition-[max-height] duration-300 " + (opened ? "max-h-[500px]" : "max-h-0")}>
                <p className="pt-6 text-xl">{answer}</p>
            </div>
        </div>
    )
}

export default Faq
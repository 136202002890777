import { Link, useLocation } from "react-router-dom"
import { FaDiscord, FaTwitter, FaInstagram } from "react-icons/fa"
import { GiHamburgerMenu } from "react-icons/gi"
import "./Header.css"
import logo from "../media/logo.png"
import { useState } from "react"

const Header = () => {
    const location = useLocation()
    const [opened, open] = useState(false)
    const pages = [
        "HOME",
        "ABOUT",
        "FAQ",
        "MINT"
    ]

    return (
        <>
            <div className={"absolute flex sp4:hidden flex-col items-center gap-12 overflow-y-scroll noscroll transition-[left] duration-500 top-0 w-full h-full bg-opacity-30 bg-[#dfe3e7] backdrop-blur-lg px-5 " + (opened ? "left-0" : "left-[100%]")}>
                {
                    pages.map((page, index) => (
                        <MobileHeaderLink text={page} key={index} classes={index == 0 ? "mt-[140px]" : ""} />
                    ))
                }

                <div className="w-[150px] flex items-center justify-between">
                    <HeaderIcon to="https://discord.gg/vucA8r5Y2s" component={<FaDiscord size={40} color="#FFF" className="mt-0" />} />
                    <HeaderIcon to="https://twitter.com/thedepressednft/" component={<FaTwitter size={40} color="#FFF" className="mt-0" />} />
                </div>
            </div>

            <div className="w-full max-w-[1823px] h-fit px-0 sp:px-5 xl:px-10 sp3:px-24 py-5 flex justify-between">
                <img src={logo} className="h-[40px] z-40" />

                <div className="hidden sp4:flex w-fit h-fit">
                    {
                        pages.map((page, index) => (
                            <HeaderLink text={page} key={index} active={location.pathname.substring(1) == page.toLowerCase()} />
                        ))
                    }
                    <HeaderIcon to="https://discord.gg/vucA8r5Y2s" component={<FaDiscord size={20} color="#FFF" className="mt-0" />} />
                    <HeaderIcon to="https://twitter.com/thedepressednft/" component={<FaTwitter size={20} color="#FFF" className="mt-0" />} />
                </div>

                <div className="flex sp4:hidden py-2 px-3 rounded-lg bg-white z-40" onClick={() => open(!opened)}>
                    <GiHamburgerMenu size={20} color="#000" className="mt-0" />
                </div>
            </div>
        </>
    )
}

const HeaderLink = ({
    text = "",
    active = false
}) => {
    return (
        <Link to={"/" + text.toLowerCase()} className="link">
            <div text={text} className={`h-[34px] px-4 rounded border border-white cursor-pointer relative flex items-center group ${active ? "bg-white" : "hover:after:content-[attr(text)] headerlink"}`}>
                <p className={`font-semibold ${active ? "text-black" : "text-white group-hover:text-black "}`}>{text}</p>
            </div>
        </Link>
    )
}

const HeaderIcon = ({
    to = "",
    component = Object
}) => {
    return (
        <a href={to} className="w-fit h-[34px] cursor-pointer rounded flex items-center px-2 link">
            {component}
        </a>
    )
}

const MobileHeaderLink = ({
    text = "",
    classes = ""
}) => {
    return (
        <Link to={"/" + text.toLowerCase()} className={"w-full flex justify-center " + classes}>
            <div text={text} className={`py-2 px-4 rounded bg-white cursor-pointer relative flex items-center group flex justify-center w-full max-w-[300px]`}>
                <p className={`font-semibold text-2xl`}>{text}</p>
            </div>
        </Link>
    )
}

export default Header
import Header from "../components/Header"
import { useEffect } from "react";
import { FaTwitter } from "react-icons/fa"
import "./About.css"
import img1 from "../media/41.png"
import img2 from "../media/63.png"
import img3 from "../media/138.jpg"
import img4 from "../media/Philosopher.jpg"

const About = () => {
	useEffect(() => {
		document.title = 'About Us';
	}, []);

	return (
		<div className="w-full min-h-screen px-10 pb-20 flex flex-col items-center relative overflow-hidden">
			<Header />
			<div className="w-full max-w-[1216px] flex justify-center mt-16">
				<svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width={233} height={74}>
					<g id="stroke" clipPath="url(#clip)" fill="none" stroke="#fff" strokeWidth="2.5px">
						<path className="st1 bombshell-draw" d="M48.4,39.7c-0.2-0.4,0.3-0.6,0.3-1c0-0.4,0-5.2-0.5-6.7c-0.5-1.5-0.7-2.2-5.6-6.5
							c-4.9-4.4-11.6-6.2-14.4-7.2s-3.7-5.4-4.5-7.4c-0.8-2-2.2-1.8-2.9-1.1c-0.7,0.7-2,3.3-2,4.4c0,0-0.1,4.6-0.1,7.2
							c0,2.6,0.9,7.1,1.3,8.6c0.4,1.5,1.7,7.1,1.9,8.6c0.2,1.4,0.9,2.2,0.8,3.2c-0.1,1-0.4,1.9,0,2.4c0.4,0.5,0.3,1.5,0.1,1.8
							s-0.5,2.1-0.3,2.5c0.2,0.5,0.1,0.9-0.1,1.6c-0.2,0.7-1.1,3.1-1.4,3.8s-0.3,2.6,0.2,2.9c0.5,0.3,0.3,1.1,2.3,4.2
							c2,3.1,5,1.5,5.3,0.9c0.3-0.7-0.1-2.4,0-2.7c0.1-0.3,1.1,0,1.8,0c0.7,0,0.3-0.3,0.4-0.4c0.1-0.1,0.3-0.1,0.3-0.3s0-0.5,0.3-0.8
							c0.3-0.2,0.9,0.1,1.6-0.3c0.7-0.4,2.9-2.6,3-3c0.1-0.4,1.3,0.3,1.3,0c0-0.3,0.1-1.5,0.1-1.5s1.4,0,2-0.6c0.6-0.6,1-1.1,1.1-2.1
							s1-1.5,1.2-1.8c0.2-0.3,1.4,0.1,1.8-0.5c0.3-0.7,0.1-1,0.4-1.4c0.2-0.4,2.4-2,3.7-3.5C49.3,41.4,48.6,40.1,48.4,39.7z M42.1,37
							c-0.3,1.8-0.7,2-1,2.6c-0.3,0.6-1,2.2-1.1,2.7s-0.4,0.4-1.9,1.8c-1.6,1.4-4.9,3.4-8,6.5c-3.1,3.1-3.2,2.7-3.4,2.1
							c-0.2-0.6-0.4-1.7-0.7-2.5s-0.3-2.8-0.3-5c0-2.2,0.1-2.4-0.2-2.8c-0.3-0.5-0.4-5.3-0.3-6.4c0.1-1,0.5-1.7,0.5-2.6
							c0-0.9,0.2-1.4,0.4-2.1c0.2-0.7,0-4,0-4.1c0,0,0-0.3,0.6-0.3c0.6,0,1.7,0.4,3.3-0.6c1.6-0.9,2.2-0.6,2.7-0.5
							c0.6,0.1,3.1,1.5,6.6,4.3C42.8,32.7,42.4,35.2,42.1,37z"
						/>
						<path className="st1 bombshell-draw" d="M86.2,8.1c-2-2.7-6.4-2.7-12.5-3c-6.1-0.3-9.6,0.2-10.3,0.4c-0.7,0.2-2.9,0.2-5.3,0.9
							c-2.4,0.6-7.8-0.1-9.4,0.3c-1.6,0.4-2,2.4-1.9,3.5c0.1,1.2,0.5,1.1,0.9,5.5c0.5,4.4,5.3,6.3,5.3,6.3c0.3,0.2,0.7-0.2,1.1-0.3
							c0.5-0.1,0.8,0.4,0.4,0.9S53.2,25,54.2,26c1,1,2.6-0.1,3.3-0.6c0.7-0.4,1-0.2,2-0.3c1-0.1,1.1-0.9,1.8-1c0.7-0.1,0.6,0.6,0.5,1.8
							c0,1.3-0.3,13.3-0.4,15.1c-0.1,1.8-1.3,2.5-0.4,3.8c0.9,1.2,1.2,1.5,1.9,4.3c0.7,2.7,2.4,2.8,3.8,2.2c1.5-0.6,1.8-3.1,1.5-4
							c-0.2-0.9,0.1-1.2-0.1-2.2c-0.2-1-0.5-0.5-0.9-2c-0.4-1.5-0.9-3.7-1-7.4s0.3-6.3,0.5-7.4c0.2-1.1,0.9-1.1,1.2-2
							c0.3-0.9,0.9-1.2,1.5-1.9c0.7-0.7,1.4-2.5,2.2-3c0.9-0.5,2.3-0.4,2.9-0.9c0.7-0.5,3-1,5.2-1.9c2.3-0.9,5.9-2.9,6.9-4.1
							C87.6,13.3,88.2,10.7,86.2,8.1z M58.4,18.4c-2.3,1.9-2.7,2.3-2.9,2.5s-0.9-0.2-0.6-2.5c0.1-0.7,4.1-3.3,5.1-4.2
							c1-0.9,1.9-0.6,2,0C62.3,14.9,60.7,16.5,58.4,18.4z M61.8,22.5c-0.5,0.1-0.7,0.1-0.6-0.2c0.1-0.6,0.5-1.6,1-1.4
							C62.7,21.1,62.2,22.4,61.8,22.5z M75.5,14.8c-1.7,0.9-3.9,2.8-4.3,2.8s-0.9-0.8-2.2-2.3c-1.3-1.5-3-2.4-3.6-2.9
							c-0.4-0.3-0.2-0.5,0-0.7c0.2-0.2,1.3,0,2-0.5c0.7-0.4,0.9-0.2,2.1-0.2c1.2,0,2.8,0.3,3.2-0.1c0.3-0.4,1.5-0.8,2.4-0.8
							c0.8,0.1,1.4,0.9,2.4,0.9c0.9,0,0.9-0.4,1.4-0.4c0.5,0,0.8,0.4,0.8,0.8C79.6,11.9,77.2,13.9,75.5,14.8z"
						/>
						<path className="st1 bombshell-draw" d="M122.2,67.2c-0.1-0.9-4-3.4-4.6-4.1c-0.7-0.7-1-0.7-1.4-1.3c-0.4-0.7-3.1-2.5-3.4-2.9
							c-0.3-0.5-1.8-1.2-2.6-2c-0.7-0.8-2.1-2-2.3-2.4s-0.4-1-1.3-1.8c-1-0.8-2.3-1.7-2.4-2.7c-0.1-1-0.2-0.8-1.3-2
							c-1-1.3-3.7-6-4.3-7.5c-0.5-1.4-0.8-6.3-0.8-7.2c0-0.9,0.6-0.6,2.5-2.9c1.9-2.3,2.8-5.2,3-7.6s-1.5-3-4.7-4c-3.2-1-3.6-2.2-4.7-4
							c-1.1-1.8-4.9-1.7-5.4-0.8c-0.6,1.2,0.1,7.6,0.1,9.8s0,6.6,0.4,7.5c0.4,0.9,0.3,1.8-0.3,3c-0.6,1.2-2.2,2.4-2,3.4
							c0.2,1.1,2.4,3.2,2.8,4.1c0.4,0.9-0.2,2.5,0.2,3.1c0.3,0.7,0.3,0.6,0.4,1.9c0.1,1.3-0.5,1.9-0.7,2.6c-0.2,0.7,0,1.2,0.3,1.9
							c0.3,0.7-0.1,1.1-0.2,1.5c-0.1,0.4,0.1,1-0.1,1.8c-0.2,0.8-0.1,2.4,1,3.2c1.1,0.9,2.9,1,3.6,0c0.7-0.9-0.4-2.5-0.7-3.5
							c-0.3-1,0.1-1.5,0.2-2.1c0.1-0.7-0.2-1.7-0.4-2.3c-0.1-0.6,0-3.9,0.1-4.1c0.1-0.2,0.4-0.8,0.5-1.2c0.1-0.4,0-2.1,0.3-2.1
							c0.3,0.1,0.3,0.8,0.3,1.4s0.6,1.8,1.4,2.6c0.8,0.7,2,2.4,2.6,3.6c0.7,1.2,2.9,2.7,3.1,3.1s0.2,1.3,0.6,1.8c0.4,0.5,2.5,1.9,3,2.2
							c0.5,0.2,4,3.5,4.1,4.1c0.1,0.6,0.5,1.4,1.2,1.6c0.7,0.2,0.9,0.7,2,1.2c1.1,0.5,1.1,1.7,1.1,2.2c0,0.5,1.4,0.7,2.6,1.6
							c1.9,1.6,3.7,1.4,4.5,1.4C121.3,69.4,122.3,68.1,122.2,67.2z M95.7,29.1c-0.6-0.4-0.9-0.4-0.9-0.8c-0.1-0.7,0.9-1.6,0.9-2.9
							c0-1.2,0.1-2.5,1.4-2s1.2,1.9,0.9,3c-0.3,1.2-1.1,1.7-1.4,2.2C96.3,29.2,96.3,29.5,95.7,29.1z"
						/>

						<path className="st1 bombshell-draw" d="M112,22.1c1-0.9,5.1-7,7-8.6s10.7-5.8,13.8-7.2c3.1-1.4,5-2.3,6.9,0c1.9,2.3,2.5,3.2,2.6,4.6s0.6,2.8,1,3.9
							s0.6,3.2-0.9,3.3c-1.5,0.1-3.8-2.5-4.6-2.6c-0.8-0.1-3.9,0.8-5.5,1.7c-1.7,0.9-2.6,0.9-4.2,2.1c-1.6,1.1-3.8,1.7-6.3,4.1
							c-2.6,2.4-4.5,4.4-4.4,5.4c0,1,3.5,1.4,6.5,1.9c3,0.5,7.7-0.3,12.3,0.5c4.6,0.9,5.9,2.1,6.9,2.3c1,0.2,0-3.2-0.4-4.2
							c-0.4-1-1-3.5,1.1-5.8c2.1-2.3,2.3-0.7,4.4-4.5c2.1-3.9,3.3-5.8,9.4-8.9c6.1-3.2,10.5-5.6,12.3-5.2c1.7,0.3,2.7-0.1,4.6,4
							c1.9,4.1,1.8,4.9,2,6c0.3,1.1,0.6,3.8-1.9,3.3c-2.5-0.4-1.9-2.2-4.2-2.5c-2.2-0.3-6.6,2.2-10.1,4c-3.5,1.8-7.9,5.3-9.1,6.9
							c-1.2,1.6-1.8,2.7,0.3,3.1s3.6,0.9,12.4,1.3c8.7,0.4,10.9,1.1,14,3.5c3.1,2.4,3.5,3.6,3.2,4.6c-0.3,1-2.8,4.6-5,6
							c-2.2,1.4-4.1,2.3-5.9,2.4c-1.8,0.1-3.2,0-5.4,0.4c-2.2,0.4-6.6,1.3-8.6,2.2c-2,0.9-4.7,0.6-4.7,0c0-0.6,1.1-1.9,4.8-3.3
							c3.7-1.4,6.7-2.3,8.3-3.6c1.6-1.3,2.5-1.4,3.8-2c1.3-0.5,3.3-2,3.5-2.6c0.2-0.5-3.3-0.6-5.2-0.8c-1.9-0.2-1.9-0.5-2.5-0.4
							c-0.6,0-1.7,0.4-3.5,0.4c-1.8,0-5.9-0.3-8.1-0.4c-2.2-0.2-1.7-0.2-2.8-0.4c-1.1-0.2-1.7-0.2-1.7,0.3s0.5,2.1,0.1,3.2
							s-7.3,5.9-9.4,6.8c-2.1,0.9-6.1,0.9-7.5,1c-1.4,0.1-2.4,0.8-4.7,1.1c-2.3,0.3-3.2,0.6-4.1,1c-0.9,0.4-2.8-0.8-2-1.8
							s4.9-2.2,6.7-3.3c1.8-1,4.6-2.2,5.5-3.1c1-0.8,3.3-1.6,4.3-2.2c1-0.5,2.5-0.8,2.6-1.5c0.1-0.7-5.6-0.8-9.3-0.8
							c-3.7,0-7.9,0-11.5-0.1c-3.6-0.1-5.9-1.4-6.8-2.6c-0.9-1.2-2.5-5-2.2-7.2C109.8,25.6,110.9,23.1,112,22.1z"
						/>

						<path className="st1 bombshell-draw" d="M221.2,38.4c-0.4-0.9-2.8-0.5-4.4-0.5s-3.9,1.3-4.2,1c-0.3-0.2,0-0.7,0.5-2c0.5-1.3,0.4-4.7-0.7-6.7
							c-1-2-4.6-5.6-6.8-7.7c-2.2-2.1-11.1-4.2-13.5-5.7s-3.2-5.3-3.6-6.7c-0.4-1.4-1.9-2.8-3-2.4c-1.7,0.6-2.6,5.2-2.7,8.5
							s1.1,10.8,1.7,13.8c0.6,3,1.2,4.7,1.8,5.8c0.6,1,0.4,2.4,0.7,3.2c0.3,0.8-0.1,2.7,0.1,3.3c0.2,0.7,0.4,1.9,0,2.7
							c-0.4,0.9,0.1,2,0.3,2.6c0.1,0.6-0.8,1.9-0.9,2.7c-0.1,0.9-0.4,1.4-1.1,2.5c-0.7,1.1,0,2.6,0.8,3.6c0.8,1.1,1.5,4.3,3.6,5.2
							c2.1,0.8,3.5-0.4,4.1-1.2c0.6-0.8-0.1-1.3-0.3-1.6c-0.2-0.3,0.1-1,1-1.3c0.9-0.3,2.8-1.2,3.8-2c1-0.7,1.5-1.8,2.4-2.1
							c0.8-0.3,1.6-0.5,2-0.9c0.3-0.4,0.2-1,0.9-1.3c0.8-0.3,1.5-1,1.9-1.4c0.4-0.4,0.8-1.5,1.1-2.1c0.3-0.6,1.2-0.7,1.4-1.2
							c0.2-0.5,0-1,1.1-2.1c1.1-1.1,3.3-2.6,5.4-3.2c2.1-0.6,1.8-0.6,4.4-1.1C221.5,39.7,221.6,39.3,221.2,38.4z M206.2,37.3
							c-0.5,1.3-2.1,4.3-3.5,5.8c-1.5,1.5-3.7,2.5-5.8,4.1c-2.1,1.6-4.5,4.6-5.3,5.1c-0.8,0.5-1-1.6-1.2-2.6c-0.2-1-0.6-2.9-0.6-4.6
							c0-1.7,0.1-2.6-0.3-3.8c-0.4-1.1,0.2-7.6,0.2-8.3c0-0.8,0.3-0.9,0.6-1.3c0.2-0.4,0-3.2,0-3.9c0-0.6,0.7-0.8,0.7-0.9
							s-0.1-0.6-0.1-0.6c0.5-0.1,2.1-0.1,3.1-0.5c1.1-0.4,1-1.2,2.4-1.1c1.3,0,4.1,1.7,6.1,3.1c2.1,1.4,3.5,3.3,3.7,4.3
							C206.3,33,206.7,35.9,206.2,37.3z"
						/>
					</g>
				</svg>
			</div>

			<p className="text-lg text-white mt-14 w-full max-w-[1216px]">
				"DPRSSD," a heartfelt NFT project created with deep empathy and a genuine desire to support individuals through their toughest moments. We all experience sadness, depression, and emotional turmoil, and DPRSSD is here to extend a helping hand and a compassionate ear to every NFT collector in need.
				<br/><br/>
				In a world where it can be challenging to find someone who truly understands, DPRSSD offers a safe space where collectors can openly express their feelings, knowing they will be met with empathy and understanding. It's a community that recognizes the weight of emotions and the need for genuine connection.
				<br/><br/>
				DPRSSD goes beyond just art; it's a movement of personal growth and transformation. It acknowledges that feeling down is not the end, but an opportunity for change and self-improvement. It encourages individuals to confront their challenges, while reminding them that they are not alone on this journey.
				<br/><br/>
				Within the DPRSSD community, you'll find a support network that cares deeply about your well-being. It's a place where heartfelt interactions, shared experiences, and valuable resources come together to guide you towards self-discovery and positive change. In moments of darkness, DPRSSD shines a light of hope and reminds you that there is a community ready to uplift, encourage, and inspire you.
				<br/><br/>
				Join the DPRSSD movement, where authenticity and compassion thrive. Together, we navigate the ups and downs, acknowledging the depth of our emotions, and embracing the power of human connection. In this collective journey, we hold space for one another, fostering growth, resilience, and a brighter tomorrow.
			</p>

			<div className="w-full max-w-[1216px] mt-28 flex flex-col items-center">
				<div className="flex items-center gap-5 w-full h-fit">
					<div className="w-full h-[2px] bg-white"></div>
					<h1 className="text-2xl text-white font-semibold">TEAM</h1>
					<div className="w-full h-[2px] bg-white"></div>
				</div>

				<div className="w-full flex gap-7 mt-16 overflow-x-scroll pb-5 scrollbar">
					<TeamCard img={img4} twitter="https://twitter.com/NFTPhilosopheRR" name="NFTPhilosopherRR" desc="Founder. Always in the trenches with the community. Have an extensive experience in building Web 2.0 businesses. Been in the space since 2020."/>
					<TeamCard img={img1} twitter="https://twitter.com/Nftnashh" name="Ash" desc="Core team. Specializes on developing customized bots for server focusing on security and automation. Previously worked with Saudis and Devs team."/>
					<TeamCard img={img2} twitter="https://twitter.com/SquishHD" name="Zayn" desc="Moderator. Previously worked in Flippr, Chimera Kings, NeonTools"/>
					<TeamCard img={img3} twitter="https://twitter.com/wsbjacky" name="Next" desc="Moderator. Worked as moderator and community manager for several NFT projects."/>
				</div>
			</div>
		</div>
	)
}

const TeamCard = ({
	img = "",
	name = "",
	desc = "",
	twitter = ""
}) =>{
	return(
		<div className="bg-[#595959] min-w-[400px] pb-3 rounded-lg overflow-hidden">
			<img src={img} className="w-full" loading="lazy"/>
			
			<div className="w-full flex items-center justify-between mt-5 px-3">
				<h2 className="text-2xl text-white font-semibold">{name}</h2>
				<a href={twitter}>
					<FaTwitter size={25} color="#FFF" className="cursor-pointer mt-0"/>
				</a>
			</div>

			<p className="text-white mt-7 px-3">
				{desc}
			</p>
		</div>
	)
}

export default About